let prefix = "/estore-orders"

const EstoreOrderApi = {
	
	getEstoreOrderInfo(id, payload){
		return window.Vue.axiosLaravel.get( prefix + "/info/"+id, {params: payload})
	},
	getEstoreOrderList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list", {params: payload})
	},
	exportEstoreOrderList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list-export", {params: payload})
	},
	updateEstoreOrderStatus(payload){
		return window.Vue.axiosLaravel.post( prefix + "/update-status", payload)
	},
	updateEstoreOrderShippingInfo(payload){
		return window.Vue.axiosLaravel.post( prefix + "/update-shipping_info", payload)
	},
}

export default EstoreOrderApi;