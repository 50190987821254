let prefix = "/order-products"

const OrderProductApi = {
	
	getEstoreFoodOrderList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list", {params: payload})
	},
	exportEstoreFoodOrderList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/list-export", {params: payload})
	},
}

export default OrderProductApi;