import axios from 'axios';

let prefix = "/eventCategory"

const EventCategoryApi = {
	
	getEventCategoryList(payload){
		return window.Vue.axios.get( prefix + "/list-admin", {params: payload})
	},
	createEventCategory(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateEventCategory(payload){
		return window.Vue.axios.put( prefix + "/update", payload)
	},
	archiveEventCategory(payload){
		return window.Vue.axios.delete( prefix + "/archive", {params: payload})
	},

}

export default EventCategoryApi;