import axios from 'axios';

let prefix = "/eventTtmId"

const EventTtmIdApi = {
	
	getEventTtmIdList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	archiveEventTtmId(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createEventTtmId(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateEventTtmId(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
}

export default EventTtmIdApi;