import axios from 'axios';

let prefix = "/crew"

const CrewAdminApi = {
	getCrewList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	createCrew(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateCrew(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
	getCrewInfo(id, payload){
		return window.Vue.axios.get( prefix + "/get/"+id, {params: payload})
	},
	getEventCrewList(payload){
		return window.Vue.axios.get( prefix + "/event-crew-list", {params: payload})
	},
	getCrewEventList(payload){
		return window.Vue.axios.get( prefix + "/crew-event-list", {params: payload})
	},
	removeEventCrew(payload){
		return window.Vue.axios.delete( prefix + "/delete-event-crew", {params: payload})
	},
	createEventCrew(payload){
		return window.Vue.axios.post( prefix + "/create-event-crew", payload)
	},
	
	// changePassword(payload){
	// 	return window.Vue.axios.post( prefix + "/updatePassword", payload)
	// },
	// updateUserProfile(payload){
	// 	return window.Vue.axios.post( prefix + "/update", payload)
	// },
	// forgotPassword(payload){
	// 	return window.Vue.axios.post( prefix + "/sendMailResetPassword", payload)
	// },
	// resetPassword(payload){
	// 	return window.Vue.axios.post( prefix + "/resetUpdatePassword", payload)
	// },
	// verifyEmail(payload){
	// 	return window.Vue.axios.post( prefix + "/verifyEmailToken", payload)
	// },	
	// getUserInfo(id, payload){
	// 	return window.Vue.axios.get( prefix + "/get/"+id, {params: payload})
	// },

	// exportUserList(payload){
	// 	return window.Vue.axios.get( prefix + "/list-export", {params: payload})
	// },


	// getUserRefs(payload){
	// 	return window.Vue.axios.get( prefix + "/refs", {params: payload})
	// },

	// getGenderTypes(payload){
	// 	return window.Vue.axios.get( prefix + "/getGenderTypes", {params: payload})
	// },

	// updateUserPassword(payload){
	// 	return window.Vue.axios.post( prefix + "/adminUpdatePassword", payload)
	// },
	// checkUserHasPassword(payload){
	// 	return window.Vue.axios.get( prefix + "/check-password", {params: payload})
	// },
	// sendAccountActivation(payload){
	// 	return window.Vue.axios.post( prefix + "/sendActivationEmail", payload)
	// },
	// verifyUserVaccination(payload){
	// 	return window.Vue.axios.post( prefix + "/verify-vaccination", payload)
	// }

}

export default CrewAdminApi;