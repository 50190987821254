import axios from 'axios';

let prefix = "/order"

const UserTicketApi = {
	
	getTicketOrderList(payload){
		return window.Vue.axios.get( prefix + "/ticket/list", {params: payload})
	},
	exportTicketOrder(payload){
		return window.Vue.axios.get( prefix + "/export", {params: payload})
	},
	cancelTicketOrder(payload){
		return window.Vue.axios.post( prefix + "/cancel", payload)
	},
}

export default UserTicketApi;