import axios from 'axios';

let prefix = "/eventPresaleCode"

const EventPresaleCodeApi = {
	
	getEventPresaleCodeList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	deleteEventPresaleCode(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createEventPresaleCode(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateEventPresaleCode(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
}

export default EventPresaleCodeApi;