import axios from 'axios';

let prefix = "/userTicket"

const UserTicketApi = {
	
	getUserTicketList(payload){
		return window.Vue.axios.get( prefix + "/get-event-id", {params: payload})
	},
	getUserTicketDetail(id, payload = null){
		return window.Vue.axios.get( prefix + "/ticket/"+id, {params: payload})
	},
	verifyTicketVaccination(payload){
		return window.Vue.axios.post( prefix + "/verify-vaccination", payload)
	},
	verifyTicketCovidTest(payload){
		return window.Vue.axios.post( prefix + "/verify-covid-test", payload)
	},
	getScanList(payload){
		return window.Vue.axios.get( prefix + "/ticket/scan-list", {params: payload})
	},
	getCovidStatusRefs(payload){
		return window.Vue.axios.get( prefix + "/covid-status", {params: payload})
	},
	exportUserTicketQr(payload){
		return window.Vue.axios.get( prefix + "/ticket/export-qr", {params: payload})
	},
	createUserTicket(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateUserTicket(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
	sendETicket(payload){
		return window.Vue.axios.post( prefix + "/send-e-ticket", payload)
	},
}

export default UserTicketApi;