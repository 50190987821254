import axios from 'axios';

let prefix = "/auditTrail"

const AuditTrailApi = {
	
	getUserTicketAuditTrailList(payload){
		return window.Vue.axios.get( prefix + "/userTicket/list", {params: payload})
	},

	getUserTicketAuditTrailInfo(payload){
		return window.Vue.axios.get( prefix + "/userTicket/info", {params: payload})
	},
}

export default AuditTrailApi;