import axios from 'axios';

let prefix = "/venue"

const VenueApi = {
	
	getVenueList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	getVenueTypes(payload){
		return window.Vue.axios.get( prefix + "/list-type", {params: payload})
	},
	getVenueInfo(id, payload){
		return window.Vue.axios.get( prefix + "/info/" + id, {params: payload})
	},
	archiveVenue(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	createVenue(payload){
		return window.Vue.axios.post( prefix + "/create", payload)
	},
	updateVenue(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
	setVenueImage(payload, onUploadProgress = {} ){
		return window.Vue.axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearVenueImage(payload){
		return window.Vue.axios.delete( prefix + "/delete-image", {params: payload})
	},
}

export default VenueApi;