
let prefix = "/product"

const ProductApi = {
	
	getProductInfo(id, payload){
		return window.Vue.axiosLaravel.get( prefix + "/info/"+id, {params: payload})
	},
	getProductSortList(){
		return window.Vue.axiosLaravel.get( prefix + "/sort-list")
	},
	getProductAll(){
		return window.Vue.axiosLaravel.get( prefix + "/all")
	},
	getProductList(payload){
		return window.Vue.axiosLaravel.get( prefix + "/listpagination", {params: payload})
	},
	createProduct(payload){
		return window.Vue.axiosLaravel.post( prefix + "/create", payload,{
			headers: { 'Content-Type': 'multipart/form-data'}
		})
	},
	updateProduct(payload){
		return window.Vue.axiosLaravel.post( prefix + "/update", payload,{
			headers: { 'Content-Type': 'multipart/form-data'}
		})
	},
	archiveProduct(payload){
		return window.Vue.axiosLaravel.delete( prefix + "/archive", {params: payload})
	},
	setProductImage(payload, onUploadProgress = {} ){
		return window.Vue.axiosLaravel.post(prefix + "/set-image", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearProductImage(payload){
		return window.Vue.axiosLaravel.delete(prefix + "/clear-image", {params: payload})
	},
	setProductVideo(payload, onUploadProgress = {} ){
		return window.Vue.axiosLaravel.post(prefix + "/set-video", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearProductVideo(payload){
		return window.Vue.axiosLaravel.delete(prefix + "/clear-video", {params: payload})
	},
	getProductRefs(payload){
		return window.Vue.axiosLaravel.get( prefix + "/refs", {params: payload})
	},
}

export default ProductApi;