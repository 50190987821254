import axios from 'axios';

let prefix = "/countries"

const CountryApi = {
	
	getCountryList(payload){
		return window.Vue.axios.get( prefix + "/list", payload)
	},

}

export default CountryApi;