import axios from 'axios';

let prefix = "/supportives"

const SupportiveApi = {
	
	getSupportive(type, payload = null){
		return window.Vue.axios.get( prefix + "/get/" + type, {params: payload})
	},

	updateSupportive(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},


}

export default SupportiveApi;