import axios from 'axios';

let prefix = "/eventTicketTypeDetail"

const EventTicketTypeDetailApi = {
	
	getEventTicketTypeDetailList(payload){
		return window.Vue.axios.get( prefix + "/list", {params: payload})
	},
	archiveEventTicketTypeDetail(payload){
		return window.Vue.axios.delete( prefix + "/delete", {params: payload})
	},
	updateEventTicketTypeDetail(payload){
		return window.Vue.axios.post( prefix + "/update", payload)
	},
}

export default EventTicketTypeDetailApi;