const CrewAdminApi = {
	getInitDeliveryMethod() {
		return window.Vue.axiosLaravel.get("/delivery-method/init");
	},
	getDeliveryMethodList(payload) {
		return window.Vue.axiosLaravel.get("/delivery-method/get", { params: payload });
	},
	createDeliveryMethod(payload) {
		return window.Vue.axiosLaravel.post("/delivery-method/create", payload);
	},
	updateDeliveryMethod(payload) {
		return window.Vue.axiosLaravel.post("/delivery-method/update", payload);
	},
	archiveDeliveryMethod(payload) {
		return window.Vue.axiosLaravel.post("/delivery-method/update", payload);
	},
};

export default CrewAdminApi;